/**
 *
 * @param app
 */
export function initialize(app) {
  if (app.ports.setItem) {
    app.ports.setItem.subscribe(function ([key, value]) {
      if (value === '') {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, value);
      }
    });
  }

  if (app.ports.getItem) {
    app.ports.getItem.subscribe(function (key) {
      if (app.ports.item) {
        const item = localStorage.getItem(key);
        app.ports.item.send([key, item]);
      }
    });
  }

  if (app.ports.getAllItems) {
    app.ports.getAllItems.subscribe(function (accountUuid) {
      if (app.ports.allItems) {
        const itemsForAccount = filterObjectByKeyPrefix(localStorage, accountUuid);
        app.ports.allItems.send(itemsForAccount);
      }
    });
  }

  if (app.ports.clearAllItems) {
    app.ports.clearAllItems.subscribe(function (accountUuid) {
      const itemsForAccount = filterObjectByKeyPrefix(localStorage, accountUuid);
      itemsForAccount.forEach(([key]) => {
        localStorage.removeItem(key);
      });
    });
  }
}

/**
 * @param {object} obj
 * @param {string} prefix
 * @returns {object} A new object with only the keys that start with the prefix
 */
export const filterObjectByKeyPrefix = (obj, prefix) => {
  const filteredEntries = Object.entries(obj).filter(([key]) => key.startsWith(prefix));
  return filteredEntries;
};
