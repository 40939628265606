import Cookies from 'js-cookie';
import { crossAppCookieDomain } from './environment';

export function saveHubspotCookiesFromQuery() {
  if (window && window.location?.search) {
    const urlParams = new URLSearchParams(window.location.search);

    const hstc = urlParams.get('__hstc');
    const hssc = urlParams.get('__hssc');
    const hsfp = urlParams.get('__hsfp');

    const setCookieIfNotEmpty = (cookieName, cookieValue, expires) => {
      if (cookieValue) {
        Cookies.set(cookieName, cookieValue, {
          domain: crossAppCookieDomain,
          expires,
        });
      }
    };

    setCookieIfNotEmpty('__hstc', hstc, 180); // ~6 months
    setCookieIfNotEmpty('__hssc', hssc, 1); // 1 day
    setCookieIfNotEmpty('__hsfp', hsfp, 1); // 1 day
  }
}
