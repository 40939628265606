// Wrap calls to URL.revokeObjectURL in a setTimeout to work around
// https://github.com/elm/file/issues/17.
//
// Remove when the issue above has been fixed.
//
// See also: https://github.com/folq/web/issues/4664
const originalRevokeObjectURL = URL.revokeObjectURL;
URL.revokeObjectURL = function () {
  setTimeout(() => {
    originalRevokeObjectURL.apply(URL, arguments);
  });
};

import 'balloon-css';
import Cookies from 'js-cookie';
import smoothScroll from 'smoothscroll-polyfill';

import { Elm } from './Main.elm';
import { saveCookiebotCookieConsentFromQuery } from './js/cookiebot.js';
import { isDevelopment, isStaging, isProd } from './js/environment.js';
import { generateSpaFlags, extBrowserSetup } from './js/ext.browser.js';
import { saveHubspotCookiesFromQuery } from './js/hubspot.js';
import { initialize as initializeMarkdownEditor } from './js/markdown-editor.js';
import { initialize as initializeAnalytics } from './Ports/analytics.js';
import { initialize as initializeLocalStorage } from './Ports/local-storage.js';
import { initialize as initializeCalendarLinks } from './Ports/calendar-links.js';
import { initialize as initializeHtmlLang } from './Ports/html-lang.js';
import { initialize as initializeTraceparent } from './Ports/traceparent.js';

// JS and CSS for elm-accordion
require('elm-accordion/dist/elm-accordion.min.js');
require('elm-accordion/dist/elm-accordion.min.css');

// Polyfill scrollIntoView with smooth behaviour for Safari. See https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView and https://github.com/iamdustan/smoothscroll
smoothScroll.polyfill();

// Fix Google Translate bug: https://insurello.se/how-we-made-elm-and-google-translate-work-together/
HTMLFontElement.prototype.replaceData = function replaceData(_0, _1, string) {
  this.parentNode.replaceChild(document.createTextNode(string), this);
};

saveCookiebotCookieConsentFromQuery();
saveHubspotCookiesFromQuery();

var spaFlags = generateSpaFlags();

const folqAuthCookieName = isDevelopment
  ? 'folq-auth-dev'
  : isStaging
    ? 'folq-auth-staging'
    : isProd
      ? 'folq-auth'
      : '';
const initialFolqAuthCookie = Cookies.get(folqAuthCookieName);

var flags = {
  spaFlags: spaFlags,
  window: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  browserLocales: window.navigator?.languages ?? [],
  time: Date.now(),
  zone: 0 - new Date().getTimezoneOffset(),
  folqAuthCookieExists: !!initialFolqAuthCookie,
};

var app = Elm.Main.init({
  node: document.getElementById('elm'),
  flags: flags,
});

extBrowserSetup(app, spaFlags, document, document.getElementById('elm-isolation'));

initializeMarkdownEditor(app);
initializeAnalytics(app);
initializeCalendarLinks(app);
initializeHtmlLang(app);
initializeLocalStorage(app);
initializeTraceparent(app);

app.ports.putSession.subscribe(function (data) {
  const newValue = JSON.stringify(data);
  app.ports.onSessionChange.send(newValue);
});

app.ports.updateMetaDescription.subscribe((tags) => {
  requestAnimationFrame(() => {
    for (const name in tags) {
      const tag = document.head.querySelector('meta[name="' + name + '"]');
      const content = tags[name];
      if (content && tag) {
        tag.setAttribute('content', content);
      } else if (content) {
        var el = document.createElement('meta');
        el.setAttribute('name', name);
        el.setAttribute('property', name);
        el.setAttribute('content', content);
        document.head.appendChild(el);
      } else if (tag) {
        document.head.removeChild(tag);
      }
    }
  });
});

function getFocusables(element) {
  return element.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
}

function focusById(id) {
  const element = document.getElementById(id);
  if (!element) {
    return;
  }
  element.focus();
}

function focusFirst(id) {
  const element = document.getElementById(id);
  if (!element) {
    return;
  }
  const focusable = getFocusables(element);
  if (focusable.length) {
    focusable[0].focus();
  }
}

function focusLast(id) {
  const element = document.getElementById(id);
  if (!element) {
    return;
  }
  const focusable = getFocusables(element);
  if (focusable.length) {
    focusable[focusable.length - 1].focus();
  }
}

function focusTemporarily(id) {
  const element = document.getElementById(id);
  const onFocusOut = () => {
    element.removeAttribute('tabindex');
    element.removeEventListener('focusout', onFocusOut);
  };

  if (!element) {
    return;
  }
  if (element.getAttribute('tabindex') === null) {
    element.setAttribute('tabindex', '-1');
    element.addEventListener('focusout', onFocusOut);
  }
  element.focus();
}

let previousCookieState = !!initialFolqAuthCookie;
function checkFolqAuthCookie() {
  const cookie = Cookies.get(folqAuthCookieName);
  if (cookie !== previousCookieState) {
    previousCookieState = cookie;
    app.ports.onFolqAuthCookieExists.send({ cookieExists: !!cookie });
  }
}
// Start the cookie check at app start. If the cookie disappears, we need to tell Elm.
setInterval(() => {
  checkFolqAuthCookie({ cookieName: folqAuthCookieName });
}, 1000);

app.ports.copyText.subscribe((text) => {
  navigator.clipboard.writeText(text).then(
    (_ok) => app.ports.copyTextResponse.send({ success: true, link: text }),
    (_fail) => app.ports.copyTextResponse.send({ success: false, link: text })
  );
});

app.ports.focusById.subscribe((id) => requestAnimationFrame(() => focusById(id)));
app.ports.focusFirst.subscribe((id) => requestAnimationFrame(() => focusFirst(id)));
app.ports.focusLast.subscribe((id) => requestAnimationFrame(() => focusLast(id)));
app.ports.focusTemporarily.subscribe((id) => requestAnimationFrame(() => focusTemporarily(id)));
window.addEventListener('scroll', () => app.ports.scrollY.send(window.pageYOffset));

app.ports.smoothScrollIdIntoView.subscribe(({ id, options }) => {
  requestAnimationFrame(() => {
    const element = document.getElementById(id);
    if (!element) {
      return;
    }
    document.getElementById(id).scrollIntoView({ behavior: 'smooth', ...options });
  });
});

app.ports.checkFolqAuthCookie.subscribe(checkFolqAuthCookie);
app.ports.askForConfirmation.subscribe((str) => app.ports.confirmationReturned.send(window.confirm(str)));
