/**
 * This code is a browser version of the middleware in ts-monorepo/packages/common/src/middleware/saveCookiebotConsentFromUrlParamToCookie.ts
 */
import Cookies from 'js-cookie';
import { crossAppCookieDomain } from './environment';

// NOTE: When changing this value, also grep for the following string in all repos
// [ref:cookiebot-consent-query-param]
const COOKIEBOT_CONSENT_QUERY_PARAM_NAME = 'cookiebot-consent';

export function saveCookiebotCookieConsentFromQuery() {
  if (window && window.location?.search) {
    const urlParams = new URLSearchParams(window.location.search);

    const urlEncodedCookiebotConsent = urlParams.get(COOKIEBOT_CONSENT_QUERY_PARAM_NAME);
    if (urlEncodedCookiebotConsent) {
      const cookiebotConsent = decodeURIComponent(urlEncodedCookiebotConsent);
      Cookies.set('CookieConsent', cookiebotConsent, {
        domain: crossAppCookieDomain,
        expires: 365, // 1 year
      });
    }
  }
}
