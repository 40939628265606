module Main exposing (CommonData, Config, Model, main)

import Api
import Api.Agreements as Agreements
import Api.Locale as I18N
import Api.Market.Extra as Market
import Api.User.Extra as User
import Api.User.RoleStatus.Extra as RoleStatus
import Api.UserManagement as UserManagement
import Api.UserRole as UserRole
import Api.UserRole.Extra as UserRole
import Browser
import Browser.Dom as Dom
import Browser.Events as Browser
import Cmd.Extra as Cmd
import Config
import Date exposing (Date)
import Element exposing (..)
import Element.Background as Background
import Element.Events as Events
import Element.Font as Font
import Element.Region as Region
import Ext.Browser
import Ext.Browser.Navigation as Nav
import Extra exposing (..)
import Focus
import Html
import Html.Attributes
import I18N
import I18N.Main as I18N
import Json.Decode as Decode
import Keyboard.Event
import Keyboard.Key
import Maybe.Extra as Maybe
import Menu exposing (SideMenuState, TopMenuState)
import Menu.InviteAColleagueModal.View as InviteAColleagueModal
import Menu.ModalMenu as ModalMenu
import Menu.SideMenu as SideMenu
import Menu.TopMenu as TopMenu
import MetaTags
import Modal
import Page exposing (Msg(..), Page)
import Page.AccountSettings as AccountSettings
import Page.AdminAssignments
import Page.Administrators
import Page.Assignment
import Page.AssignmentEditor as AssignmentEditor
import Page.AssignmentOverview
import Page.AssignmentWizard as AssignmentWizard
import Page.Assignments
import Page.Companies
import Page.CompanyEditor as CompanyEditor
import Page.Competencies
import Page.CompetenciesEditor
import Page.ConfirmAvailability
import Page.ConsultantBuyers
import Page.ConsultantCompanies
import Page.ConsultantCompanySettings
import Page.Consultants
import Page.ContractInfo
import Page.EditAccount as EditAccount
import Page.FindConsultants
import Page.FindConsultants.Desktop
import Page.FindConsultants.Mobile
import Page.FolqNumbers
import Page.Footer as Footer
import Page.Home as Home
import Page.Home.View as Home
import Page.Invitation
import Page.Messages as Messages
import Page.Messages.View as Messages
import Page.ProfileComparison as ProfileComparison
import Page.ProfileWizard
import Page.SavedProfiles as SavedProfiles
import Page.SavedSearches as SavedSearches
import Page.UIExplorer
import Page.Verified as Verified
import Page.Welcome
import Ports.Analytics
import Ports.HtmlLang
import RemoteData
import Route
import Scroll
import ScrollPort
import Session
import Task
import Time exposing (Posix)
import Type.Component.Msg as Component
import Type.Document as Document
import Type.Flags as Flags
import Type.Screen as Screen exposing (Screen)
import Type.Session as Session exposing (Session)
import Type.Uuid as Uuid
import Type.Window exposing (Window)
import UI
import UI.Color
import UI.Dialog as Dialog
import UI.Font
import UI.Link as Link
import UI.OnPress exposing (OnPressTarget(..))
import UI.Pixels exposing (..)
import UI.Spacing as Spacing
import UI.Transitions
import Url exposing (Url)
import Url.Parts
import WebData exposing (WebData)


main : Program Decode.Value Model Msg
main =
    Browser.element
        { init = init
        , view = view
        , update = update
        , subscriptions = subscriptions
        }


init : Decode.Value -> ( Model, Cmd Msg )
init json =
    let
        { window, time, zone, spaFlags, browserLocales, folqAuthCookieExists } =
            Flags.decode json

        { url, navKey } =
            Nav.spaFlags { spaFlags = spaFlags }

        locale =
            Session.getUserLocaleWithDefault browserLocales url Session.None

        config =
            { key = navKey
            , landingUrl = url
            , baseUrl = Url.Parts.toBase url
            , zone = zone
            , initTime = time
            , today = Date.fromPosix zone time
            , window = window
            , screen = Screen.fromWindow window
            , urlChangeTriggeredFromLinkClick = False
            , locale = locale
            , userManagementAccount = RemoteData.NotAsked
            , hasSignedCooperationContract = RemoteData.NotAsked
            , folqAuthCookieExists = folqAuthCookieExists
            }

        ( page, pageCmd ) =
            Page.initFrom config
                { oldPage = Nothing
                , session = Session.None
                , url = url
                , wasLoggedOut = False
                }

        ( menu, menuCmd ) =
            let
                shouldOpenInviteAColleagueModal =
                    Maybe.unwrap False (String.contains Route.openInviteAColleagueQueryString) url.query
            in
            Menu.init Session.None { shouldOpenInviteAColleagueModal = shouldOpenInviteAColleagueModal }

        ( sessionModel, sessionModelCmd ) =
            Session.init config Session.None

        model =
            { config = config
            , url = url
            , reloadEntireAppOnNextOccasion = False
            , scroll = { y = 0 }
            , page = page
            , menu = menu
            , commonData = { unreadMessageCount = RemoteData.NotAsked }
            , sessionModel = sessionModel
            , showSkipLink = False
            , showKeyboardShortcuts = False
            , visibility = Browser.Visible
            , sideMenuState = Menu.Closed
            , topMenuState = Menu.Visible
            }
    in
    ( model
    , Cmd.batch
        [ pageCmd
        , Cmd.map GotMenuMsg menuCmd
        , getUnreadMessageCount <| Page.toSession page
        , Focus.onLoad
        , MetaTags.update <| Page.toTags config url
        , sessionModelCmd
        , Ports.HtmlLang.setHtmlLang <| I18N.localeToString locale
        , Ports.Analytics.initAnalytics Config.mixpanelProjectToken
        ]
    )


{-| Pretty much static data, so safe to use as an argument to lazy elements
-}
type alias Config =
    { key : Nav.Key
    , landingUrl : Url.Url
    , baseUrl : Url.Parts.Base
    , zone : Time.Zone
    , initTime : Posix
    , today : Date
    , window : Window
    , screen : Screen
    , urlChangeTriggeredFromLinkClick : Bool
    , locale : I18N.Locale
    , userManagementAccount : WebData UserManagement.Account
    , hasSignedCooperationContract : WebData Agreements.HasSignedCooperationContractResponse
    , folqAuthCookieExists : Bool
    }


type alias Model =
    { config : Config
    , url : Url
    , reloadEntireAppOnNextOccasion : Bool
    , scroll : { y : Int }
    , page : Page
    , menu : Menu.Model
    , commonData : CommonData
    , sessionModel : Session.Model
    , showSkipLink : Bool
    , showKeyboardShortcuts : Bool
    , visibility : Browser.Visibility
    , sideMenuState : SideMenuState
    , topMenuState : TopMenuState
    }


type alias CommonData =
    { unreadMessageCount : WebData Int
    }


subscriptions : Model -> Sub Msg
subscriptions model =
    Sub.batch
        [ Browser.onResize Resized
        , Nav.onUrlRequest LinkClicked
        , Nav.onUrlChange UrlChanged
        , Browser.onVisibilityChange VisibilityChanged
        , ScrollPort.scrollY <| ScrolledY << round
        , Sub.map GotSessionMsg <| Session.subscriptions model.visibility
        , Sub.map GotMenuMsg <| Menu.subscriptions model.visibility model.menu model.sideMenuState
        , Browser.onKeyDown (Decode.map HandleKeyboardShortcutEvent Keyboard.Event.decodeKeyboardEvent)
        , case model.visibility of
            Browser.Hidden ->
                Sub.none

            Browser.Visible ->
                Time.every (10 * 1000) <| always UpdateUnreadMessageCount
        , case model.page of
            Page.Assignment assignment ->
                Sub.map GotAssignmentMsg <| Page.Assignment.subscriptions assignment

            Page.AssignmentOverview assignmentOverview ->
                Sub.map GotAssignmentOverviewMsg <| Page.AssignmentOverview.subscriptions assignmentOverview

            Page.AdminAssignments adminAssignments ->
                Sub.map GotAdminAssignmentsMsg <| Page.AdminAssignments.subscriptions adminAssignments

            Page.Assignments assignments ->
                Sub.map GotAssignmentsMsg <| Page.Assignments.subscriptions assignments

            Page.AssignmentEditor assignmentEditor ->
                Sub.map GotAssignmentEditorMsg <| AssignmentEditor.subscriptions assignmentEditor

            Page.AssignmentWizard assignmentWizard ->
                Sub.map GotAssignmentWizardMsg <| AssignmentWizard.subscriptions assignmentWizard

            Page.CompanyEditor companyEditor ->
                Sub.map GotCompanyEditorMsg <| CompanyEditor.subscriptions companyEditor

            Page.Home _ ->
                Sub.none

            Page.Verified _ ->
                Sub.none

            Page.Invitation _ ->
                Sub.none

            Page.Messages messages ->
                Sub.map GotMessagesMsg <| Messages.subscriptions messages

            Page.ProfileComparison _ ->
                Sub.none

            Page.Administrators administrators ->
                Sub.map GotAdministratorsMsg <| Page.Administrators.subscriptions administrators

            Page.ConsultantBuyers consultantBuyers ->
                Sub.map GotConsultantBuyersMsg <| Page.ConsultantBuyers.subscriptions consultantBuyers

            Page.Consultants consultants ->
                Sub.map GotConsultantsMsg <| Page.Consultants.subscriptions consultants

            Page.ContractInfo contractInfo ->
                Sub.map GotContractInfoMsg <| Page.ContractInfo.subscriptions contractInfo

            Page.Companies companies ->
                Sub.map GotCompaniesMsg <| Page.Companies.subscriptions companies

            Page.ConsultantCompanies consultantCompanies ->
                Sub.map GotConsultantCompaniesMsg <| Page.ConsultantCompanies.subscriptions consultantCompanies

            Page.EditAccount editAccount ->
                Sub.map GotEditAccountMsg <| EditAccount.subscriptions editAccount

            Page.AccountSettings _ ->
                Sub.none

            Page.ProfileWizard _ ->
                Sub.none

            Page.FindConsultants findConsultants ->
                Sub.map GotFindConsultantsMsg <| Page.FindConsultants.subscriptions findConsultants

            Page.SavedProfiles savedProfiles ->
                Sub.map GotSavedProfilesMsg <| SavedProfiles.subscriptions savedProfiles

            Page.SavedSearches savedSearches ->
                Sub.map GotSavedSearchesMsg <| SavedSearches.subscriptions savedSearches

            Page.UIExplorer _ ->
                Sub.none

            Page.Welcome _ ->
                Sub.none

            Page.ConsultantCompanySettings _ ->
                Sub.none

            Page.FolqNumbers folqNumbers ->
                Sub.map GotFolqNumbersMsg <| Page.FolqNumbers.subscriptions folqNumbers

            Page.Competencies _ ->
                Sub.none

            Page.CompetenciesEditor _ ->
                Sub.none

            Page.ConfirmAvailability _ ->
                Sub.none
        ]


keyboardShortcutsFor : I18N.Translations -> Session.Session -> List { name : String, description : Element msg }
keyboardShortcutsFor t session =
    let
        notLoggedInShortcuts =
            [ { name = "⌥ + Esc", description = text t.aapnelukkeDenneVisningenAvAlleSnarveier }
            , { name = "⌥ + S", description = text t.aapnelukkeSidemenyen }
            ]

        commonLoggedInShortcuts =
            [ { name = "⌥ + M", description = text t.gaaTilMeldinger }
            , { name = "⌥ + O", description = text t.gaaTilOppdrag }
            ]
    in
    case session of
        Session.None ->
            notLoggedInShortcuts

        Session.LoggedIn login ->
            notLoggedInShortcuts
                ++ commonLoggedInShortcuts
                ++ (case User.toUserRole login.user of
                        UserRole.Admin ->
                            [ { name = "⌥ + F", description = text t.gaaTilFinnKonsulenter }
                            , { name = "⌥ + K", description = text t.gaaTilKonsulenter }
                            , { name = "⌥ + P", description = text t.gaaTilOppdragsgivere }
                            , { name = "⌥ + V", description = text t.gaaTilFavoritter }
                            , { name = "⌥ + L", description = text t.gaaTilLagredeSoek }
                            , { name = "⌥ + T", description = text t.gaaTilFolqetall }
                            , { name = "⌥ + E", description = text t.gaaTilSelskaper }
                            , { name = "⌥ + R", description = text t.gaaTilKonsulentselskaper }
                            ]

                        UserRole.Consultant ->
                            [ { name = "⌥ + P", description = text t.gaaTilProfil }
                            , { name = "⌥ + K", description = text t.gaaTilAlleKonsulenter }
                            , { name = "⌥ + I", description = text t.gaaTilInnstillinger }
                            ]

                        UserRole.ConsultantBuyer ->
                            [ { name = "⌥ + F", description = text t.gaaTilFinnKonsulenter }
                            , { name = "⌥ + V", description = text t.gaaTilFavoritter }
                            , { name = "⌥ + L", description = text t.gaaTilLagredeSoek }
                            ]
                   )


handleKeyboardShortcutEvents : Page -> Config -> Model -> Keyboard.Event.KeyboardEvent -> ( Model, Cmd Msg )
handleKeyboardShortcutEvents page config model { altKey, keyCode } =
    let
        maybeLogin =
            case Page.toSession page of
                Session.None ->
                    Nothing

                Session.LoggedIn login ->
                    Just login

        maybeUserRole =
            Maybe.map (User.toUserRole << .user) maybeLogin

        closeShowKeyboardShortcuts : ( Model, Cmd Msg ) -> ( Model, Cmd Msg )
        closeShowKeyboardShortcuts =
            Tuple.mapFirst (\model_ -> { model_ | showKeyboardShortcuts = False })
    in
    -- Remember to update keyboardShortcuts when adding new shortcuts
    case ( maybeUserRole, altKey, keyCode ) of
        -- START: #7809 Remove this block (currently lines 461-468) to enable keyboard shortcuts for Consultant and ConsultantBuyer
        ( Just UserRole.Consultant, _, _ ) ->
            ( model, Cmd.none )

        ( Just UserRole.ConsultantBuyer, _, _ ) ->
            ( model, Cmd.none )

        -- END: #7809 Remove this block (currently lines 461-468) to enable keyboard shortcuts for Consultant and ConsultantBuyer
        ( Nothing, _, _ ) ->
            ( model, Cmd.none )

        ( _, True, Keyboard.Key.Escape ) ->
            ( { model | showKeyboardShortcuts = not model.showKeyboardShortcuts }, Cmd.none )

        ( _, False, Keyboard.Key.Escape ) ->
            closeShowKeyboardShortcuts ( model, Cmd.none )

        ( _, True, Keyboard.Key.S ) ->
            closeShowKeyboardShortcuts ( { model | sideMenuState = Menu.opositeSideMenuStateOf model.sideMenuState }, Cmd.none )

        ( _, True, Keyboard.Key.F ) ->
            closeShowKeyboardShortcuts
                ( model
                , Route.push config.key <|
                    Route.FindConsultants <|
                        Route.emptyFindConsultantsParametersFromURL config (Maybe.unwrap Market.all (.markets << .user) maybeLogin)
                )

        ( _, True, Keyboard.Key.M ) ->
            closeShowKeyboardShortcuts ( model, Route.push config.key <| Route.Messages Route.MessagesAssignmentThreads )

        ( Just UserRole.Admin, True, Keyboard.Key.O ) ->
            closeShowKeyboardShortcuts ( model, Route.push config.key <| Route.AdminAssignments <| Route.emptyAdminAssignmentsParameters config.today )

        ( Just UserRole.Admin, True, Keyboard.Key.K ) ->
            closeShowKeyboardShortcuts ( model, Route.push config.key <| Route.Consultants Nothing Nothing Nothing )

        ( Just UserRole.Admin, True, Keyboard.Key.P ) ->
            closeShowKeyboardShortcuts ( model, Route.push config.key <| Route.ConsultantBuyers Nothing )

        ( Just UserRole.Admin, True, Keyboard.Key.V ) ->
            closeShowKeyboardShortcuts ( model, Route.push config.key <| Route.SavedProfiles Nothing )

        ( Just UserRole.Admin, True, Keyboard.Key.L ) ->
            closeShowKeyboardShortcuts ( model, Route.push config.key Route.SavedSearches )

        ( Just UserRole.Admin, True, Keyboard.Key.T ) ->
            closeShowKeyboardShortcuts ( model, Route.push config.key Route.initThreadsPage )

        ( Just UserRole.Admin, True, Keyboard.Key.E ) ->
            closeShowKeyboardShortcuts ( model, Route.push config.key <| Route.Companies Nothing )

        ( Just UserRole.Admin, True, Keyboard.Key.R ) ->
            closeShowKeyboardShortcuts ( model, Route.push config.key <| Route.ConsultantCompanies Nothing )

        -- START: #7809 Uncomment to enable for Consultant and ConsultantBuyer
        -- ( Just UserRole.Consultant, True, Keyboard.Key.O ) ->
        --     closeShowKeyboardShortcuts ( model, Route.push config.key <| Route.Assignments Route.AssignmentsTop Route.emptyAssignmentsParameters )
        -- ( Just UserRole.Consultant, True, Keyboard.Key.P ) ->
        --     closeShowKeyboardShortcuts ( model, Route.push config.key <| Route.EditMyProfile Nothing )
        -- ( Just UserRole.Consultant, True, Keyboard.Key.K ) ->
        --     closeShowKeyboardShortcuts ( model, Route.push config.key Route.MyConsultantCompanyProfiles )
        -- ( Just UserRole.Consultant, True, Keyboard.Key.I ) ->
        --     closeShowKeyboardShortcuts ( model, Route.push config.key Route.MyAccountSettings )
        -- ( Just UserRole.ConsultantBuyer, True, Keyboard.Key.O ) ->
        --     closeShowKeyboardShortcuts ( model, Route.push config.key <| Route.Assignments Route.AssignmentsTop Route.emptyAssignmentsParameters )
        -- ( Just UserRole.ConsultantBuyer, True, Keyboard.Key.V ) ->
        --     closeShowKeyboardShortcuts ( model, Route.push config.key Route.SavedProfiles )
        -- ( Just UserRole.ConsultantBuyer, True, Keyboard.Key.L ) ->
        --     closeShowKeyboardShortcuts ( model, Route.push config.key Route.SavedSearches )
        -- END: #7809 Uncomment to enable for Consultant and ConsultantBuyer
        _ ->
            ( model, Cmd.none )


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    let
        config =
            model.config

        ignore =
            ( model, Cmd.none )

        maybeLogin =
            case Page.toSession model.page of
                Session.None ->
                    Nothing

                Session.LoggedIn login ->
                    Just login

        updateTopMenuState y model_ =
            let
                scrolledUp =
                    y < model.scroll.y

                scrolledDown =
                    y > model.scroll.y
            in
            { model_
                | topMenuState =
                    if scrolledUp then
                        Menu.Visible

                    else if scrolledDown then
                        Menu.Hidden

                    else
                        model.topMenuState
            }
    in
    case ( msg, model.page ) of
        ( DomTasks _, _ ) ->
            ignore

        ( LinkClicked urlRequest, _ ) ->
            let
                modelWithoutReloadEntireAppOnNextOccasion =
                    { model | reloadEntireAppOnNextOccasion = False }
            in
            case urlRequest of
                Ext.Browser.Internal url ->
                    ( { modelWithoutReloadEntireAppOnNextOccasion | config = { config | urlChangeTriggeredFromLinkClick = True } }
                    , Cmd.batch
                        [ if model.reloadEntireAppOnNextOccasion then
                            Nav.load <| Url.toString url

                          else
                            Nav.pushUrl config.key <| Url.toString url
                        , scrollToTopOrFragmentElement url.fragment
                        , Focus.onLoad
                        , Ports.Analytics.trackEvent
                            { name = "Internal link clicked"
                            , properties =
                                { actingAccount = Maybe.map (\login -> Uuid.toString login.user.account) maybeLogin
                                , actingRole = Maybe.map (\login -> UserRole.toValueString <| RoleStatus.toUserRole login.user.role) maybeLogin
                                , profile = Nothing
                                , target = Just <| Url.toString url
                                }
                            }
                        ]
                    )

                Ext.Browser.External href ->
                    ( modelWithoutReloadEntireAppOnNextOccasion
                    , Cmd.batch
                        [ Nav.load href
                        , Ports.Analytics.trackEvent
                            { name = "External link clicked"
                            , properties =
                                { actingAccount = Maybe.map (\login -> Uuid.toString login.user.account) maybeLogin
                                , actingRole = Maybe.map (\login -> UserRole.toValueString <| RoleStatus.toUserRole login.user.role) maybeLogin
                                , profile = Nothing
                                , target = Just href
                                }
                            }
                        ]
                    )

        ( UrlChanged ( shouldRunInit, url ), page ) ->
            let
                newSession =
                    Page.toSession model.page

                ( newPage, cmds ) =
                    if shouldRunInit then
                        Page.initFrom
                            config
                            { oldPage = Just model.page
                            , session = newSession
                            , url = url
                            , wasLoggedOut = False
                            }

                    else
                        ( page, Cmd.none )

                document =
                    getDocument config model newPage

                title =
                    case model.commonData.unreadMessageCount of
                        RemoteData.Success 0 ->
                            document.title

                        RemoteData.Success unreadMessages ->
                            "(" ++ String.fromInt unreadMessages ++ ") " ++ document.title

                        _ ->
                            document.title

                ( menu, menuCmd ) =
                    if shouldRunInit then
                        -- Initialize the menu with possible open modal state if the user was just logged in (i.e. came from Home.Callback)
                        case page of
                            Page.Home { subPage } ->
                                case subPage of
                                    Home.Callback _ ->
                                        Menu.init newSession { shouldOpenInviteAColleagueModal = Maybe.unwrap False (String.contains Route.openInviteAColleagueQueryString) url.query }

                                    _ ->
                                        ( Menu.closeModalMenu model.menu, Cmd.none )

                            _ ->
                                ( Menu.closeModalMenu model.menu, Cmd.none )

                    else
                        ( model.menu, Cmd.none )
            in
            ( { model
                | page = newPage
                , menu = menu
                , url = url
                , sideMenuState = Menu.Closed
                , config = { config | urlChangeTriggeredFromLinkClick = False }
              }
            , Cmd.batch
                [ cmds
                , MetaTags.update <| Page.toTags config url
                , Ext.Browser.setPageTitle title
                , Ports.Analytics.trackEvent
                    { name = "Url changed"
                    , properties =
                        { actingAccount = Maybe.map (\login -> Uuid.toString login.user.account) maybeLogin
                        , actingRole = Maybe.map (\login -> UserRole.toValueString <| RoleStatus.toUserRole login.user.role) maybeLogin
                        , profile = Nothing
                        , target = Just <| Url.toString url
                        }
                    }
                ]
            )
                |> Cmd.addCmd (Cmd.map GotMenuMsg menuCmd)

        ( GotSessionMsg (Component.Internal internalMsg), _ ) ->
            Session.update
                config
                (Page.toSession model.page)
                internalMsg
                model.sessionModel
                |> Tuple.mapBoth
                    (\sessionModel -> { model | sessionModel = sessionModel })
                    (Cmd.map GotSessionMsg)

        ( GotSessionMsg (Component.External (Session.GotFolqAuthCookieExistsStatus folqAuthCookieExists)), _ ) ->
            ( { model | config = { config | folqAuthCookieExists = folqAuthCookieExists } }, Cmd.none )

        ( GotSessionMsg (Component.External (Session.GotNewSession newSession)), _ ) ->
            let
                oldSession =
                    Page.toSession model.page

                wasLoggedOut =
                    case ( oldSession, newSession ) of
                        ( Session.LoggedIn _, Session.None ) ->
                            True

                        _ ->
                            False

                ( ( userLocaleIfWasJustLoggedIn, userManagementAccountIfWasJustLoggedIn, userManagementAccountCmdIfWasJustLoggedIn ), ( hasSignedCooperationContractIfWasJustLoggedIn, hasSignedCooperationContractCmdIfWasJustLoggedIn ) ) =
                    case ( oldSession, newSession ) of
                        ( Session.None, Session.LoggedIn login ) ->
                            ( ( login.user.locale, RemoteData.Loading, getUserManagementAccount )
                            , ( RemoteData.Loading, getHasSignedCooperationContract )
                            )

                        _ ->
                            ( ( config.locale, config.userManagementAccount, Cmd.none )
                            , ( config.hasSignedCooperationContract, Cmd.none )
                            )

                ( menu, menuCmd ) =
                    Menu.init newSession { shouldOpenInviteAColleagueModal = Maybe.unwrap False (String.contains Route.openInviteAColleagueQueryString) config.landingUrl.query }
            in
            Page.initFrom
                config
                { oldPage = Nothing
                , session = newSession
                , url = model.url
                , wasLoggedOut = wasLoggedOut
                }
                |> Tuple.mapFirst
                    (\page ->
                        { model
                            | config =
                                { config
                                    | locale = userLocaleIfWasJustLoggedIn
                                    , userManagementAccount = userManagementAccountIfWasJustLoggedIn
                                    , hasSignedCooperationContract = hasSignedCooperationContractIfWasJustLoggedIn
                                }
                            , menu = menu
                            , page = page
                        }
                    )
                |> Cmd.addCmd (Cmd.map GotMenuMsg menuCmd)
                |> Cmd.addCmd userManagementAccountCmdIfWasJustLoggedIn
                |> Cmd.addCmd hasSignedCooperationContractCmdIfWasJustLoggedIn

        ( GotSessionMsg (Component.External Session.NewApiVersionDetected), _ ) ->
            ( { model | reloadEntireAppOnNextOccasion = True }, Cmd.none )

        ( Resized x y, _ ) ->
            let
                window =
                    { width = x, height = y }
            in
            ( { model | config = { config | window = window, screen = Screen.fromWindow window } }, Cmd.none )

        ( VisibilityChanged visibility, _ ) ->
            ( { model | visibility = visibility }, Cmd.map GotSessionMsg <| Session.onVisibilityChanged visibility )

        ( ScrolledY y, Page.AdminAssignments adminAssignments ) ->
            Page.AdminAssignments.update config (Page.AdminAssignments.UpdateScrollY y) adminAssignments
                |> updateWith Page.AdminAssignments GotAdminAssignmentsMsg
                |> Tuple.mapFirst (updateTopMenuState y << asPageIn { model | scroll = { y = y } })

        ( ScrolledY y, Page.Assignments assignments ) ->
            Page.Assignments.update config (Page.Assignments.UpdateScrollY y) assignments
                |> updateWith Page.Assignments GotAssignmentsMsg
                |> Tuple.mapFirst (updateTopMenuState y << asPageIn { model | scroll = { y = y } })

        ( ScrolledY y, _ ) ->
            ( { model | scroll = { y = y } }, Cmd.none )
                |> Tuple.mapFirst (updateTopMenuState y)

        ( UnreadMessageCountResponse response, existingPage ) ->
            let
                commonData_ =
                    model.commonData

                ( newPage, cmd ) =
                    case existingPage of
                        Page.Messages messages ->
                            case ( model.commonData.unreadMessageCount, response ) of
                                ( oldCount, RemoteData.Success newCount ) ->
                                    if newCount > RemoteData.withDefault 0 oldCount then
                                        Messages.updateGetThreadsAndThreadMessages messages
                                            |> updateWith Page.Messages GotMessagesMsg

                                    else
                                        ( existingPage, Cmd.none )

                                _ ->
                                    ( existingPage, Cmd.none )

                        _ ->
                            ( existingPage, Cmd.none )
            in
            ( { model | commonData = { commonData_ | unreadMessageCount = response }, page = newPage }, cmd )

        ( UpdateUnreadMessageCount, page ) ->
            ( model, getUnreadMessageCount <| Page.toSession page )

        ( ShowSkipLink s, _ ) ->
            ( { model | showSkipLink = s }, Cmd.none )

        ( CloseKeyboardShortcuts, _ ) ->
            ( { model | showKeyboardShortcuts = False }, Cmd.none )

        ( HandleKeyboardShortcutEvent event, page ) ->
            handleKeyboardShortcutEvents page config model event

        ( GotMenuMsg (Component.External externalMsg), _ ) ->
            case externalMsg of
                Menu.OpenSideMenu ->
                    ( { model | sideMenuState = Menu.Open }
                    , Dom.focus "side-menu" |> Task.attempt DomTasks
                    )

                Menu.CloseSideMenu ->
                    ( { model | sideMenuState = Menu.Closed }
                    , Cmd.none
                    )

                Menu.ShowKeyboardShortcuts ->
                    ( { model | showKeyboardShortcuts = True }
                    , Cmd.none
                    )

                Menu.SetLocale locale ->
                    let
                        config_ =
                            model.config
                    in
                    ( { model | config = { config_ | locale = locale } }
                    , Ports.HtmlLang.setHtmlLang <| I18N.localeToString locale
                    )

        ( GotMenuMsg (Component.Internal internalMsg), _ ) ->
            Menu.update config { session = Page.toSession model.page } internalMsg model.menu
                |> updateWith (\menu -> { model | menu = menu }) GotMenuMsg

        ( GotHomeMsg subMsg, Page.Home home ) ->
            Home.update config subMsg home
                |> updateWith Page.Home GotHomeMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotHomeMsg _, _ ) ->
            ignore

        ( GotInvitationMsg subMsg, Page.Invitation invitation ) ->
            Page.Invitation.update config subMsg invitation
                |> updateWith Page.Invitation GotInvitationMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotInvitationMsg _, _ ) ->
            ignore

        ( GotUIExplorerMsg _, _ ) ->
            ignore

        ( GotProfileComparisonMsg subMsg, Page.ProfileComparison profileComparison ) ->
            ProfileComparison.update subMsg profileComparison
                |> updateWith Page.ProfileComparison GotProfileComparisonMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotProfileComparisonMsg _, _ ) ->
            ignore

        ( GotAdministratorsMsg subMsg, Page.Administrators administrators ) ->
            Page.Administrators.update model subMsg administrators
                |> updateWith Page.Administrators GotAdministratorsMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotAdministratorsMsg _, _ ) ->
            ignore

        ( GotConsultantBuyersMsg subMsg, Page.ConsultantBuyers consultantBuyers ) ->
            Page.ConsultantBuyers.update model subMsg consultantBuyers
                |> updateWith Page.ConsultantBuyers GotConsultantBuyersMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotConsultantBuyersMsg _, _ ) ->
            ignore

        ( GotConsultantsMsg subMsg, Page.Consultants consultants ) ->
            Page.Consultants.update model subMsg consultants
                |> updateWith Page.Consultants GotConsultantsMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotConsultantsMsg _, _ ) ->
            ignore

        ( GotCompaniesMsg subMsg, Page.Companies companies ) ->
            Page.Companies.update model subMsg companies
                |> updateWith Page.Companies GotCompaniesMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotCompaniesMsg _, _ ) ->
            ignore

        ( GotConsultantCompaniesMsg subMsg, Page.ConsultantCompanies companies ) ->
            Page.ConsultantCompanies.update model subMsg companies
                |> updateWith Page.ConsultantCompanies GotConsultantCompaniesMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotConsultantCompaniesMsg _, _ ) ->
            ignore

        ( GotEditAccountMsg subMsg, Page.EditAccount editAccount ) ->
            EditAccount.update config subMsg editAccount
                |> updateWith Page.EditAccount GotEditAccountMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotEditAccountMsg _, _ ) ->
            ignore

        ( GotAccountSettingsMsg subMsg, Page.AccountSettings accountSettings ) ->
            AccountSettings.update subMsg accountSettings
                |> updateWith Page.AccountSettings GotAccountSettingsMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotAccountSettingsMsg _, _ ) ->
            ignore

        ( GotProfileWizardMsg subMsg, Page.ProfileWizard profileWizard ) ->
            Page.ProfileWizard.update config subMsg profileWizard
                |> updateWith Page.ProfileWizard GotProfileWizardMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotProfileWizardMsg _, _ ) ->
            ignore

        ( GotFindConsultantsMsg subMsg, Page.FindConsultants findConsultants ) ->
            Page.FindConsultants.update config subMsg findConsultants
                |> updateWith Page.FindConsultants GotFindConsultantsMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotFindConsultantsMsg _, _ ) ->
            ignore

        ( GotSavedProfilesMsg subMsg, Page.SavedProfiles savedProfiles ) ->
            SavedProfiles.update config subMsg savedProfiles
                |> updateWith Page.SavedProfiles GotSavedProfilesMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotSavedProfilesMsg _, _ ) ->
            ignore

        ( GotSavedSearchesMsg subMsg, Page.SavedSearches savedSearches ) ->
            SavedSearches.update config subMsg savedSearches
                |> updateWith Page.SavedSearches GotSavedSearchesMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotSavedSearchesMsg _, _ ) ->
            ignore

        ( GotAdminAssignmentsMsg subMsg, Page.AdminAssignments adminAssignments ) ->
            Page.AdminAssignments.update config subMsg adminAssignments
                |> updateWith Page.AdminAssignments GotAdminAssignmentsMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotAdminAssignmentsMsg _, _ ) ->
            ignore

        ( GotAssignmentsMsg subMsg, Page.Assignments assignments ) ->
            Page.Assignments.update config subMsg assignments
                |> updateWith Page.Assignments GotAssignmentsMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotAssignmentsMsg _, _ ) ->
            ignore

        ( GotAssignmentMsg subMsg, Page.Assignment assignment ) ->
            Page.Assignment.update config subMsg assignment
                |> updateWith Page.Assignment GotAssignmentMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotAssignmentMsg _, _ ) ->
            ignore

        ( GotAssignmentOverviewMsg subMsg, Page.AssignmentOverview assignment ) ->
            Page.AssignmentOverview.update config subMsg assignment
                |> updateWith Page.AssignmentOverview GotAssignmentOverviewMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotAssignmentOverviewMsg _, _ ) ->
            ignore

        ( GotAssignmentEditorMsg subMsg, Page.AssignmentEditor assignmentEditor ) ->
            AssignmentEditor.update config model subMsg assignmentEditor
                |> updateWith Page.AssignmentEditor GotAssignmentEditorMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotAssignmentEditorMsg _, _ ) ->
            ignore

        ( GotAssignmentWizardMsg subMsg, Page.AssignmentWizard assignmentWizard ) ->
            AssignmentWizard.update config subMsg assignmentWizard
                |> updateWith Page.AssignmentWizard GotAssignmentWizardMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotAssignmentWizardMsg _, _ ) ->
            ignore

        ( GotWelcomeMsg subMsg, Page.Welcome welcome ) ->
            Page.Welcome.update subMsg welcome
                |> updateWith Page.Welcome GotWelcomeMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotWelcomeMsg _, _ ) ->
            ignore

        ( GotMessagesMsg subMsg, Page.Messages messages ) ->
            Messages.update config subMsg messages
                |> updateWith Page.Messages GotMessagesMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotMessagesMsg _, _ ) ->
            ignore

        ( GotCompanyEditorMsg subMsg, Page.CompanyEditor companyEditor ) ->
            CompanyEditor.update config subMsg companyEditor
                |> updateWith Page.CompanyEditor GotCompanyEditorMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotCompanyEditorMsg _, _ ) ->
            ignore

        ( GotConsultantCompanySettingsMsg subMsg, Page.ConsultantCompanySettings consultantCompanySettings ) ->
            Page.ConsultantCompanySettings.update subMsg consultantCompanySettings
                |> updateWith Page.ConsultantCompanySettings GotConsultantCompanySettingsMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotConsultantCompanySettingsMsg _, _ ) ->
            ignore

        ( GotContractInfoMsg subMsg, Page.ContractInfo contractInfo ) ->
            Page.ContractInfo.update subMsg contractInfo
                |> updateWith Page.ContractInfo GotContractInfoMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotContractInfoMsg _, _ ) ->
            ignore

        ( GotFolqNumbersMsg subMsg, Page.FolqNumbers folqNumbers ) ->
            Page.FolqNumbers.update model subMsg folqNumbers
                |> updateWith Page.FolqNumbers GotFolqNumbersMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotFolqNumbersMsg _, _ ) ->
            ignore

        ( GotCompetenciesMsg subMsg, Page.Competencies role ) ->
            Page.Competencies.update subMsg role
                |> updateWith Page.Competencies GotCompetenciesMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotCompetenciesMsg _, _ ) ->
            ignore

        ( GotCompetenciesEditorMsg subMsg, Page.CompetenciesEditor role ) ->
            Page.CompetenciesEditor.update subMsg role
                |> updateWith Page.CompetenciesEditor GotCompetenciesEditorMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotCompetenciesEditorMsg _, _ ) ->
            ignore

        ( GotConfirmAvailabilityMsg subMsg, Page.ConfirmAvailability confirmAvailability ) ->
            Page.ConfirmAvailability.update subMsg confirmAvailability
                |> updateWith Page.ConfirmAvailability GotConfirmAvailabilityMsg
                |> Tuple.mapFirst (asPageIn model)

        ( GotConfirmAvailabilityMsg _, _ ) ->
            ignore

        ( GotUserManagementAccount response, _ ) ->
            ( { model | config = { config | userManagementAccount = response } }, Cmd.none )

        ( GotHasSignedCooperationContract response, _ ) ->
            ( { model | config = { config | hasSignedCooperationContract = response } }, Cmd.none )

        ( NoOp, _ ) ->
            ignore


getUnreadMessageCount : Session -> Cmd Msg
getUnreadMessageCount session =
    case session of
        Session.None ->
            Cmd.none

        Session.LoggedIn _ ->
            Cmd.map (RemoteData.fromResult >> UnreadMessageCountResponse) Api.getThreadsUnread


getUserManagementAccount : Cmd Msg
getUserManagementAccount =
    Cmd.map (RemoteData.fromResult >> GotUserManagementAccount) UserManagement.getAccount


getHasSignedCooperationContract : Cmd Msg
getHasSignedCooperationContract =
    Cmd.map (RemoteData.fromResult >> GotHasSignedCooperationContract) Agreements.getHasSignedCooperationContract


asPageIn : Model -> Page -> Model
asPageIn model page =
    { model | page = page }


scrollToTopOrFragmentElement : Maybe String -> Cmd Msg
scrollToTopOrFragmentElement maybeFragment =
    case maybeFragment of
        Nothing ->
            Scroll.toTop |> Task.attempt DomTasks

        Just fragment ->
            Cmd.batch
                [ Dom.getElement fragment
                    |> Task.andThen
                        (\{ element } ->
                            Scroll.to (element.y - 130)
                        )
                    |> Task.attempt DomTasks
                , Focus.focusTemporarily fragment
                ]


view : Model -> Html.Html Msg
view ({ config, page, scroll, menu, showSkipLink } as model) =
    let
        { screen } =
            config

        menuModalView =
            Modal.map GotMenuMsg <| ModalMenu.view config page menu model.commonData

        inviteColleagueModalView =
            Modal.map GotMenuMsg <| InviteAColleagueModal.view menu config

        t =
            I18N.usingLocale config.locale

        document =
            getDocument config model page

        isOpenPage =
            case page of
                Page.Home _ ->
                    True

                _ ->
                    False

        menuElement =
            Maybe.unwrap none (Element.map GotMenuMsg << TopMenu.view config screen scroll page model.topMenuState model.commonData) document.menu

        session =
            Page.toSession model.page

        footer =
            if document.footer then
                Footer.view config session { isOpenPage = isOpenPage }

            else
                none

        mainContentId =
            "main-content"

        skipLink =
            el
                (if showSkipLink then
                    [ padding 15, Background.color UI.Color.white ]

                 else
                    [ width <| px 0, height <| px 0, clip ]
                )
            <|
                Link.text
                    [ Font.size 18
                    , Events.onFocus <| ShowSkipLink True
                    , Events.onLoseFocus <| ShowSkipLink False
                    ]
                    { onPress = UI.OnPress.Open <| UsingStringUrl <| "#" ++ mainContentId
                    , label = t.tilHovedinnhold
                    }

        skipLinkElement =
            if Screen.isSmallScreen screen then
                skipLink

            else
                -- Must be the same as or higher than the side menu, which has zIndex 21 to be in front of inFront/onRight/onLeft elements, which have zIndex 20
                Element.el [ Element.inFront skipLink, UI.zIndex 21 ] Element.none

        keyboardShortcutsDialog =
            Dialog.simple config.locale [] { isOpen = model.showKeyboardShortcuts, fullscreen = False, closeMsg = CloseKeyboardShortcuts, noOp = NoOp } <|
                column [ Spacing.s32 ]
                    [ UI.textParagraph UI.Font.h1 t.hurtigtaster
                    , table
                        [ width fill, Spacing.xy PX32 PX16 ]
                        { columns =
                            [ { header = el UI.Font.h5 <| text t.tastekombinasjon
                              , width = shrink
                              , view = UI.code << .name
                              }
                            , { header = el UI.Font.h5 <| text t.handling
                              , width = fill
                              , view = el [ centerY ] << .description
                              }
                            ]
                        , data = keyboardShortcutsFor t session
                        }
                    ]

        addBackdropIf condition =
            inFront <|
                el
                    ([ height fill
                     , width fill
                     , UI.Transitions.fastTransition [ "background-color", "visibility" ]
                     , UI.zIndex 21
                     ]
                        ++ (if condition then
                                [ Background.color <| UI.Color.withAlpha 0.25 UI.Color.black
                                , Element.mapAttribute GotMenuMsg <| Events.onClick <| Component.External Menu.CloseSideMenu
                                ]

                            else
                                UI.hidden
                           )
                    )
                    none

        -- If we always use row, some pages look ugly when window width is less than 1450px.
        -- See https://github.com/folq/web/issues/3455 and https://github.com/folq/web/issues/7543
        ( sideMenu, rowOrColumn ) =
            let
                desktopAndLoggedIn =
                    ( Element.map GotMenuMsg <| SideMenu.view config page model.sideMenuState menu
                    , row
                    )

                mobileOrNotLoggedIn =
                    ( none, column )
            in
            if Screen.isSmallScreen screen || Maybe.isNothing document.menu then
                mobileOrNotLoggedIn

            else
                case page of
                    Page.Home _ ->
                        mobileOrNotLoggedIn

                    _ ->
                        case Page.toSession page of
                            Session.LoggedIn _ ->
                                desktopAndLoggedIn

                            Session.None ->
                                mobileOrNotLoggedIn

        preventScrollWhenSideMenuIsOpenOnDesktopOrOnMessagesPageAttributes =
            case ( model.sideMenuState, Screen.isLargeScreen config.screen ) of
                ( Menu.Open, True ) ->
                    [ Html.Attributes.style "overflow-y" "hidden" ]

                _ ->
                    []
    in
    Html.div preventScrollWhenSideMenuIsOpenOnDesktopOrOnMessagesPageAttributes
        [ layout UI.Font.bodyM <|
            Modal.with inviteColleagueModalView <|
                Modal.with menuModalView <|
                    Modal.with document.modal <|
                        rowOrColumn [ width fill, height fill ]
                            [ column
                                [ width fill
                                , height fill
                                , Events.onClick CloseKeyboardShortcuts
                                , addBackdropIf (model.sideMenuState == Menu.Open)
                                ]
                                [ skipLinkElement
                                , menuElement
                                , keyboardShortcutsDialog
                                , el [ Region.mainContent, UI.id mainContentId, width fill, height fill ] document.body
                                , footer
                                ]
                            , sideMenu
                            ]
        ]


getDocument : Config -> Model -> Page -> Document.Document Msg
getDocument config model page =
    case page of
        Page.Home home ->
            Document.map GotHomeMsg <| Home.view config model.url home

        Page.Verified verified ->
            Verified.view config.locale verified

        Page.Invitation invitation ->
            Document.map GotInvitationMsg <| Page.Invitation.view config invitation

        Page.UIExplorer _ ->
            Document.map GotUIExplorerMsg <| Page.UIExplorer.view config

        Page.Administrators administrators ->
            Document.map GotAdministratorsMsg <| Page.Administrators.view config administrators

        Page.ConsultantBuyers consultantBuyers ->
            Document.map GotConsultantBuyersMsg <| Page.ConsultantBuyers.view config consultantBuyers

        Page.Consultants consultants ->
            Document.map GotConsultantsMsg <| Page.Consultants.view config consultants

        Page.Companies companies ->
            Document.map GotCompaniesMsg <| Page.Companies.view config companies

        Page.ConsultantCompanies companies ->
            Document.map GotConsultantCompaniesMsg <| Page.ConsultantCompanies.view config companies

        Page.EditAccount editAccount ->
            Document.map GotEditAccountMsg <| EditAccount.view config editAccount

        Page.AccountSettings accountSettings ->
            Document.map GotAccountSettingsMsg <| AccountSettings.view config accountSettings

        Page.ProfileWizard profileWizard ->
            Document.map GotProfileWizardMsg <| Page.ProfileWizard.view config profileWizard

        Page.ProfileComparison profileComparison ->
            Document.map GotProfileComparisonMsg <| ProfileComparison.view config profileComparison

        Page.FindConsultants findConsultants ->
            let
                view_ =
                    case config.screen of
                        Screen.Mobile ->
                            Page.FindConsultants.Mobile.view

                        Screen.Tablet ->
                            Page.FindConsultants.Mobile.view

                        _ ->
                            Page.FindConsultants.Desktop.view
            in
            Document.map GotFindConsultantsMsg <| view_ config model.menu model.scroll findConsultants

        Page.SavedProfiles savedProfiles ->
            Document.map GotSavedProfilesMsg <| SavedProfiles.view config savedProfiles

        Page.SavedSearches savedSearches ->
            Document.map GotSavedSearchesMsg <| SavedSearches.view config savedSearches

        Page.AdminAssignments adminAssignments ->
            Document.map GotAdminAssignmentsMsg <| Page.AdminAssignments.view config adminAssignments

        Page.Assignments assignments ->
            Document.map GotAssignmentsMsg <| Page.Assignments.view config assignments

        Page.Assignment assignment ->
            Document.map GotAssignmentMsg <| Page.Assignment.view config assignment

        Page.AssignmentOverview assignment ->
            Document.map GotAssignmentOverviewMsg <| Page.AssignmentOverview.view config assignment

        Page.AssignmentEditor assignmentEditor ->
            Document.map GotAssignmentEditorMsg <| AssignmentEditor.view config assignmentEditor

        Page.AssignmentWizard assignmentWizard ->
            Document.map GotAssignmentWizardMsg <| AssignmentWizard.view config assignmentWizard

        Page.Welcome welcome ->
            Document.map GotWelcomeMsg <| Page.Welcome.view config welcome config.window

        Page.Messages messages ->
            Document.map GotMessagesMsg <| Messages.view config messages

        Page.CompanyEditor companyEditor ->
            Document.map GotCompanyEditorMsg <| CompanyEditor.view config companyEditor

        Page.ConsultantCompanySettings consultantCompanySettings ->
            Document.map GotConsultantCompanySettingsMsg <| Page.ConsultantCompanySettings.view config consultantCompanySettings

        Page.ContractInfo contractInfo ->
            Document.map GotContractInfoMsg <| Page.ContractInfo.view config contractInfo

        Page.FolqNumbers folqNumbers ->
            Document.map GotFolqNumbersMsg <| Page.FolqNumbers.view config folqNumbers

        Page.Competencies competencies ->
            Document.map GotCompetenciesMsg <| Page.Competencies.view config.locale competencies

        Page.CompetenciesEditor competenciesEditor ->
            Document.map GotCompetenciesEditorMsg <| Page.CompetenciesEditor.view config.locale competenciesEditor

        Page.ConfirmAvailability confirmAvailability ->
            Document.map GotConfirmAvailabilityMsg <| Page.ConfirmAvailability.view config confirmAvailability
