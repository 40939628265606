export const isDevelopment = window.location.hostname.includes('localhost');
export const isStaging = window.location.hostname.includes('app.test.folq.com');
export const isProd = window.location.hostname.includes('app.folq.com');

export const crossAppCookieDomain = isDevelopment
  ? 'localhost'
  : isStaging
    ? 'test.folq.com'
    : isProd
      ? 'folq.com'
      : '';
